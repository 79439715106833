import React from 'react';

const Tutorial = () => {
      return (
            <div className='container'>
                  <div className='row'>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-4'>
                              <h2 className='text-center'>Available Tutorial</h2>
                        </div>
                  </div>
                  <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-10 mx-auto my-4'>
                              <div className="card text-center" style={{ width: "18rem" }}>
                                    <img src="../../images/youtube-removebg-preview.png" className="img-fluid" alt="youtube preview" />
                                    <div className="card-body">
                                          <h5 className="card-title">HTML Tutorial</h5>
                                          <p className="card-text">In this tutorial you will get all the video related to HTML from basic to advanced.</p>
                                          <a href="https://www.youtube.com/playlist?list=PL_MLc8gOHh4qCU8IzTB0Gf6hiH93y1eQa" target="_blank" className="btn btn-primary">Go to Tutorial</a>
                                    </div>
                              </div>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-10 mx-auto my-4'>
                              <div className="card text-center" style={{ width: "18rem" }}>
                                    <img src="../../images/youtube-removebg-preview.png" className="img-fluid" alt="youtube preview" />
                                    <div className="card-body">
                                          <h5 className="card-title">Python Tutorial</h5>
                                          <p className="card-text">In this tutorial you will get all the video related to Python from basic to advanced.</p>
                                          <a href="https://www.youtube.com/playlist?list=PL_MLc8gOHh4rhe6Xlt9ekY2KTNvRbmYg4" target="_blank" className="btn btn-primary">Go to Tutorial</a>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      )
}

export default Tutorial;
