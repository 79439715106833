import React from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Banner from "./Banner.js";
import Navbar from "./Navbar.js";
import Contact from "./Contact.js";
import Header from "./Header.js";
import Home from "./Home.js";
import '../css/style.css';
import Tutorial from "./Tutorial.js";
import Footer from "./Footer.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Portfolio from "./Portfolio.js";
import Shop from "./Shop.js";
import About from "./About.js";


const App = () => {
  return (
    <div className="overflow-x-hidden">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tutorial" element={<Tutorial />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
