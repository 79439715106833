import React from 'react';
import { FaBars } from "react-icons/fa";
import { Link, NavLink } from 'react-router-dom';
import Contact from './Contact';

const Navbar = () => {
      return (
            <div className="row"><nav className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 navbar navbar-expand-lg bg-body-tertiary">
                  <div className="container-fluid">
                        <h5><NavLink className="navbar-brand" to="/">Visionary Web</NavLink></h5>
                        <div className='mobile-toggler d-lg-none'>
                              <NavLink to="/" data-bs-toggle="modal" data-bs-target="#navbModal">
                                    <FaBars />
                              </NavLink>
                        </div>
                        <div className='collapse navbar-collapse navb-items'>
                              <div className='item'>
                                    {/* <a href="/">Home</a> */}
                                    <NavLink to="/">Home</NavLink>
                              </div>
                              <div className='item'>
                                    {/* <a href="/">Tutorial</a> */}
                                    <NavLink to="/tutorial">Tutorial</NavLink>
                              </div>
                              <div className='item'>
                                    {/* <a href="/">Portfolio</a> */}
                                    <NavLink to="/portfolio">Portfolio</NavLink>
                              </div>
                              <div className='item'>
                                    {/* <a href="/">Shop</a> */}
                                    <NavLink to="/shop">Shop</NavLink>
                              </div>
                              <div className='item'>
                                    {/* <a href="/">About</a> */}
                                    <NavLink to="/about">About</NavLink>
                              </div>
                              <div className='item'>
                                    {/* <a href="/" className='btn btn-outline-light bg-secondary'>Contact Us</a> */}
                                    <NavLink to="/contact" className='btn btn-outline-light bg-secondary'>Contact Us</NavLink>
                              </div>
                        </div>
                  </div>
            </nav>
                  {/* Modal */}
                  <div className="modal fade" id="navbModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                              <div className="modal-content">
                                    <div className="modal-header">
                                          <h5><NavLink to="/">Visionary Web</NavLink></h5>
                                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                          <div className='modal-line py-3'>
                                                {/* <a href="/">Home</a> */}
                                                <NavLink to="/">Home</NavLink>
                                          </div>
                                          <div className='modal-line py-3'>
                                                {/* <a href="/">Tutorial</a> */}
                                                <NavLink to="/tutorial">Tutorial</NavLink>
                                          </div>
                                          <div className='modal-line py-3'>
                                                {/* <a href="/">Portfolio</a> */}
                                                <NavLink to="/portfolio">Portfolio</NavLink>
                                          </div>
                                          <div className='modal-line py-3'>
                                                {/* <a href="/">About</a> */}
                                                <NavLink to="/shop">Shop</NavLink>
                                          </div>
                                          <div className='modal-line py-3'>
                                                {/* <a href="/">About</a> */}
                                                <NavLink to="/about">About</NavLink>
                                          </div>
                                          {/* <a href="/" className='btn btn-outline-light bg-secondary py-3'>Contact Us</a> */}
                                          <NavLink to="/contact" className='btn btn-outline-light bg-secondary'>Contact Us</NavLink>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      )
}

export default Navbar;