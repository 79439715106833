import React, { useState } from "react";

const Contact = () => {
      // const [contactData, setContactData] = useState({
      //       name: "",
      //       phone: "",
      //       email: "",
      //       address: "",
      //       message: "",
      // });
      return (
            <>
                  <div className="container mt-5">
                        <div className="row">
                              <div className="col-md-6 m-auto">
                                    <h2 className="text-center">GET IN TOUCH</h2>
                                    <h5 className="text-center">Have any queries or suggestion? Drop us a message.</h5>
                              </div>
                              <div className="col-md-6">
                                    <form className="row g-3">
                                          <div className="col-md-6">
                                                <label for="name" className="form-label">Name</label>
                                                <input type="text" className="form-control" id="name" />
                                          </div>
                                          <div className="col-md-6">
                                                <label for="phone" className="form-label">Phone</label>
                                                <input type="text" className="form-control" id="phone" />
                                          </div>
                                          <div className="col-12">
                                                <label for="email" className="form-label">Email</label>
                                                <input type="email" className="form-control" id="email" />
                                          </div>
                                          <div className="col-12">
                                                <label for="address" className="form-label">Address</label>
                                                <input type="text" className="form-control" id="address" />
                                          </div>
                                          <div className="col-md-12">
                                                <label for="message" className="form-label">Message</label>
                                                <input type="text" className="form-control" id="message" />
                                          </div>
                                          <div className="col-12">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                          </div>
                                    </form>
                              </div>
                        </div>
                  </div>
            </>
      )
}

export default Contact;