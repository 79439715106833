import React from "react";
import Banner from "./Banner";
import Tutorial from "./Tutorial";
import Shop from "./Shop";

const Home = () => {
      return (
            <>
                  <Banner />
                  <Tutorial />
                  <Shop />
            </>
      );
}

export default Home;