import React from 'react';
import { Link } from "react-router-dom";

const Banner = () => {
      return (
            <div className='container'>
                  <div className='row'>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-10 mx-auto my-auto'>
                              <img src="../../images/7607115_3670301.jpg" class="img-fluid" alt="youtube preview" />
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-10 mx-auto my-auto text-center'>
                              <h5>We are mainly focusing on coding and web development. We cover lots of stuff such as coding, web development and web technology. It is a process of detecting and correcting bit errors in digital communication systems.</h5>
                              <a className="btn btn-outline-primary mt-3" href="https://www.youtube.com/@VisionaryWebb" target="_blank">Check our channel</a>
                        </div>
                  </div>
            </div>
      )
}

export default Banner;