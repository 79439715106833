import React from 'react';
import { FaYoutube, FaInstagram, FaFacebook } from "react-icons/fa";

const Footer = () => {
      return (
            <div className='container-fluid mt-5' style={{ backgroundColor: "#acaeb0" }}>
                  <div className='row'>
                        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto mt-5 py-3'>
                              <footer className="text-center text-dark">
                                    <div className="">
                                          <span className='fs-2' style={{ marginInline: "3%" }}><FaYoutube /></span>
                                          <span className='fs-2' style={{ marginInline: "3%" }} ><FaInstagram /></span>
                                          <span className='fs-2' style={{ marginInline: "3%" }}><FaFacebook /></span>
                                    </div>
                                    <div className="text-center mt-4" style={{ backgroundColor: "rgba(0, 0, 0, 0.2);" }}>© 2024 Copyright:
                                          <a className="" href="https://visionaryweb.in">Visionary Web</a>
                                    </div>
                              </footer>
                        </div>
                  </div >
            </div >
      )
}

export default Footer;