import React from "react";

const Shop = () => {
      return (
            <>
                  <div className='container'>
                        <div className='row'>
                              <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 my-4'>
                                    <h2 className='text-center'>Available Product</h2>
                              </div>
                        </div>
                        <div className='row'>
                              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-10 mx-auto my-4'>
                                    <div className="card text-center" style={{ width: "18rem" }}>
                                          <img src="../../images/mitspro.png" className="img-fluid" alt="youtube preview" />
                                          <div className="card-body">
                                                <h5 className="card-title">Mits Pro Fibre</h5>
                                                <p className="card-text">Mits Pro Fibre - Pack of 100g Powder</p>
                                                <a href="https://amzn.to/3T9YZ9Y" target="_blank" className="btn btn-primary">Buy from amazon</a>
                                          </div>
                                    </div>
                              </div>
                              <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-10 mx-auto my-4'>
                                    <div className="card text-center" style={{ width: "18rem" }}>
                                          <img src="../../images/pentasure.png" className="img-fluid" alt="youtube preview" />
                                          <div className="card-body">
                                                <h5 className="card-title">PentaSure 2.0 Protein </h5>
                                                <p className="card-text">PentaSure 2.0 - Lean Weight Gainer- For Men and Women Whey Protein  (1 kg, Vanilla)</p>
                                                <a href="https://fkrt.co/ZB08Dv" target="_blank" className="btn btn-primary">Buy from Flipkart</a>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      );
}

export default Shop;